.alternate-bg-card {
    div.chakra-card:nth-child(even) {
        background-color: var(--chakra-colors-background-action-default);
    }
}

/* React Quill - Rich Text Editor
 https://github.com/zenoamaro/react-quill */

.ql-editor {
    min-height: 300px;
}

/* Bulletin Styles */
.chakra-alert ul,
.chakra-alert ol {
    margin-left: 2rem;
}

.chakra-alert a {
    text-decoration: underline;
}
